import { template as template_7ba4634ae94c4e2c960bbadbcdbd4b40 } from "@ember/template-compiler";
const FKText = template_7ba4634ae94c4e2c960bbadbcdbd4b40(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
