import { template as template_6fba28aaf5f24e7081f90c543d8a1276 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_6fba28aaf5f24e7081f90c543d8a1276(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
