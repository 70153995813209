import { template as template_140f5996db9a4b8f93741311ca99e140 } from "@ember/template-compiler";
const FKControlMenuContainer = template_140f5996db9a4b8f93741311ca99e140(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
