import { template as template_d06e9139873e4faab42e027b2c27a830 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_d06e9139873e4faab42e027b2c27a830(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
